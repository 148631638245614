<template>
  <div class="readme-article">
    <h2 id="企业话术库">4.企业话术库</h2>
    <h3 id="话术库分组">4.1话术库分组</h3>
    <p>
      话术库分组分组，管理员可新增、编辑、删除分组；点击分组列表自动筛选该分组的话术。
    </p>
    <p>
      <img src="@/assets/img/scrm/3-9.png" />
    </p>
    <h3 id="话术库列表">4.2话术库列表</h3>
    <p>
      查看已添加的话术库列表，包含话术内容、标题、创建人、创建时间等信息，管理员可新增、编辑、删除话术。
    </p>
    <p>
      <img src="@/assets/img/scrm/3-10.png" />
    </p>
    <h3 id="话术库编辑">4.3话术库编辑</h3>
    <p>
      新建编辑话术库，话术可添加多个内容。
    </p>
    <p>
      <img src="@/assets/img/scrm/3-11.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm3-4",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>